import React from 'react';

export default class Notice extends React.Component {

  render() {
    return (
      <>
        <h2><strong>システム更新</strong></h2><br className="sp"/><h3>令和6年8月30日(金)</h3>
        <ul>
          <li>建築情報マップ（団地認定、協定地区）を令和6年6月6日現在に更新しました。</li>
          <li>次回更新は令和6年9月下旬頃を予定しています。</li>
        </ul>
        <br />
      </>
    );
  }

}